import styled from "@emotion/styled";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <PageIntro
        description={
          <>
            <p>
              At QASkills, we value your privacy and are committed to protecting
              your personal information.
            </p>
            <p>
              This Privacy Policy outlines how we collect, use, and safeguard
              the data you provide to us when using our QA e-learning platform.
            </p>
            By accessing and using our platform, you acknowledge that you have
            read and understood the practices described in this policy.
          </>
        }
        subtitle="Privacy Policy"
        title=""
      />
      <VerticalLine />

      <StyledAccordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Privacy Policy</Accordion.Header>
          <Accordion.Body>
            Your data is kept safe. Your personal and contact information,
            except for public information that the user chooses to be visible to
            other users, will never be made public. By using QASkills we can
            save cookies on your computer. In partnership with third-party
            advertisers, they may place a cookie on your computer, but your
            personally identifiable information will not be made public or
            shared with third parties. Together with independent advertisers, we
            may collect and process your data such as: IP address, the browser
            you use, the server you are connected to. We can use cookies to
            display ads. In order to protect users and copyrights, we reserve
            the right to investigate, report and initiate legal actions in
            accordance with legal possibilities. Given that we create the
            profile solely based on your consent through a social network
            (Google, Github, LinkedIn, Facebook), you can withdraw your consent
            at any time and send a request to delete the profile by email to
            office@qaskills.io, after which we will delete all data we have
            about you on our applications.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="2">
        <Accordion.Item eventKey="2">
          <Accordion.Header>Payment methods</Accordion.Header>
          <Accordion.Body>
            Payment on our platform can be made in one of the following ways: -
            Payment cards
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="3">
        <Accordion.Item eventKey="3">
          <Accordion.Header>Statement of Conversion</Accordion.Header>
          <Accordion.Body>
            All payments will be effected in Serbian currency - Dinar (RSD). The
            amount your credit card account will be charged for is obtained
            through the conversion of the price in Euro into Serbian dinar
            according to the current exchange rate of the Serbian National Bank.{" "}
            <br />
            <br />
            When charging your credit card, the same amount is converted into
            your local currency according to the exchange rate of credit card
            associations. As a result of this conversion there is a possibility
            of a slight difference from the original price stated in our web
            site.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="4">
        <Accordion.Item eventKey="4">
          <Accordion.Header>Complaint procedure</Accordion.Header>
          <Accordion.Body>
            Courses sold through our online platform are a form of delivery of
            digital content that is not delivered on a permanent record carrier
            but online through the presentation of lessons, videos, audio
            recordings, quizzes and games on the platform. The company QASkills
            d.o.o. does not assume any responsibility for customer errors,
            wrongly purchased products or any other errors related to your
            actions when purchasing on the website and in these cases complaints
            are not possible. If you have any irregularities when purchasing
            courses on the website or you have not received the purchased
            course, please contact the customer service office@qaskills.io and
            we will do our best to eliminate the irregularities so that you can
            receive the purchased course. If you want to start the complaint
            process, you need to contact us at office@qaskills.io with a
            complaint request. In the case of a refund to a customer who
            previously paid with one of the payment cards, partially or in full,
            and regardless of the reason for the refund, this refund is made
            exclusively via the same VISA, Maestro or MasterCard card that was
            used for payment. This means that our bank will refund the funds to
            the cardholder's account at our request.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="5">
        <Accordion.Item eventKey="5">
          <Accordion.Header>Right to a refund</Accordion.Header>
          <Accordion.Body>
            In case of cancellation of the exchange rate or dissatisfaction, the
            consumer has the right to request a refund if the complaint is
            submitted within 14 days from the date of payment, and if no more
            than 1/4 (one quarter) of the exchange rate has been exceeded. It is
            not possible to deviate from the rules due to the policy on the
            QASkills platform. The exchange is made for a course in the same
            price range, cheaper or by paying the difference for a more
            expensive one. The request is sent electronically to
            office@qaskills.io.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="6">
        <Accordion.Item eventKey="6">
          <Accordion.Header>Refund costs</Accordion.Header>
          <Accordion.Body>
            The cost of returning goods and money is borne by the buyer.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="7">
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Protection of confidential transaction data
          </Accordion.Header>
          <Accordion.Body>
            When entering payment card data, confidential information is
            transmitted via a public network in a protected (encrypted) form
            using the SSL protocol and the PKI system, as currently the most
            modern cryptographic technology.
            <br />
            <br />
            Data security during purchases is guaranteed by the payment card
            processor. Payment card information is not available to our system
            at any moment.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="8">
        <Accordion.Item eventKey="8">
          <Accordion.Header>Cookies</Accordion.Header>
          <Accordion.Body>
            A website cookie, also known as an HTTP cookie or a browser cookie,
            is a small piece of data that is stored on a user's device (such as
            a computer or smartphone) by a website. Cookies are created by
            websites and are sent to the user's browser, where they are stored
            for future reference. QASkills uses third-party cookies (which are
            set by a server located outside the domain of our website).
            <br />
            Types of Cookies QASkills uses:
            <br />
            Session Cookies:
            <br />
            Certain cookies are necessary in order for QASkills' website to work
            as expected. When you log on to our website, authentication cookies
            are set. These cookies also facilitate the social media login
            functionality (Google) on our website.
            <br />
            Analytics Cookies:
            <br />
            We may use analytics to collect information about your use of the
            QASkills website to create reports and statistics of the website.
            Analytics collect information such as your IP address, type of
            device, operating system, geolocation, time and date of page visits,
            and which pages you visit. We get the overall patterns of usage on
            the platform, help us record any difficulties you have with the
            website.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="9">
        <Accordion.Item eventKey="9">
          <Accordion.Header>Withdrawal Policy</Accordion.Header>
          <Accordion.Body>
            In case of cancellation of the exchange rate or dissatisfaction, the
            consumer has the right to request a refund if the complaint is
            submitted within 14 days from the date of payment, and if no more
            than 1/4 (one quarter) of the exchange rate has been exceeded. It is
            not possible to deviate from the rules due to the policy on the
            QASkills platform. The exchange is made for a course in the same
            price range, cheaper or by paying the difference for a more
            expensive one. The request is sent electronically to
            office@qaskills.io.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const StyledAccordion = styled(Accordion)`
  @media (min-width: 768px) {
    width: 90%;
    margin: auto;
  }

  @media (min-width: 1200px) {
    width: 60%;
    margin: auto;
  }
`;
