import styled from "@emotion/styled";

import quantox from "../../../assets/images/companies/Quantox-Technology-logo.png";
import wackerNeuson from "../../../assets/images/companies/Wacker-Neuson-logo.png";
import foodtec from "../../../assets/images/companies/foodtec.jpg";

import djemboPersonPlaceholder from "../../../assets/images/review-persons/djembo.jpeg";
import aleksandarPersonPlaceholder from "../../../assets/images/review-persons/aleksandar.jpg";
import atanasiosPersonPlaceholder from "../../../assets/images/review-persons/anastasios.jpg";

import { Testimonial } from "../../header-pages/landing-page/testimonials/Testimonial";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const Reviews = () => {
  return (
    <>
      <Container>
        <PageIntro
          description={
            <>
              <p>Have something to say about QASkills? Great!</p> We love to
              hear feedback from people.{" "}
              <p>
                Feel free to share feedback about your experience with us below.
              </p>
            </>
          }
          subtitle="We're dedicated to your success."
          title="Reviews"
          showOutlinedButton={true}
        />

        <Description className="animate__animated animate__pulse">
          Check out below the reviews from clients who gained the skills,
          knowledge and confidence to start their careers as QA engineers after
          completing our learning platform.
        </Description>

        <VerticalLine />

        <Cards>
          <StyledTestimonial>
            <Testimonial
              name="Stefan"
              testimonial={
                <>
                  <p>
                    QASkills appeared at a time when I was a bit tired of
                    reading and watching different QA-related courses, which all
                    looked similar, and none of them gave me a true vision of
                    what it takes to learn, so I can dare to apply for the real
                    QA position.
                  </p>
                  <p>
                    I needed a different approach, and the QASkills concept
                    seemed like the perfect match for me as I was searching for
                    a single place where I could learn and practically check and
                    track what I've learned right away.
                  </p>
                  <p>
                    Going through the flow of QASkills lessons and tasks, I was
                    getting the knowledge much easier, and I got from zero to
                    ready at no time.
                  </p>
                  <p>
                    I passed the interview and started working as a junior QA.
                  </p>
                  <p>
                    I was surprised at how essential the lessons from the
                    QASkills were for actual tasks at work.
                  </p>
                  I'm writing this to say thank you, guys, and of course, I
                  would highly recommend QASkills to all the future and current
                  QA's.
                </>
              }
              companyLogo={quantox}
              position="Junior QA Engineer"
              personPlaceholder={djemboPersonPlaceholder}
            />
          </StyledTestimonial>

          <StyledTestimonial>
            <Testimonial
              name="Anastasios"
              testimonial={
                <>
                  <p>
                    QASkills helped me in my early journey towards becoming a
                    Software QA engineer.
                  </p>
                  <p>
                    It integrates both theoretical and practical knowledge,
                    guiding you through writing test cases, conducting API
                    testing, improving your coding skills, mastering SQL
                    queries, and much more.
                  </p>
                  These essentials collectively prepare you for a successful
                  career in QA testing.
                </>
              }
              companyLogo={foodtec}
              position="Software QA engineer"
              personPlaceholder={atanasiosPersonPlaceholder}
            />
          </StyledTestimonial>

          <StyledTestimonial>
            <Testimonial
              name="Aleksandar"
              testimonial="Fantastic Platform for Beginners and Juniors QA Testers! ⭐⭐⭐⭐⭐"
              companyLogo={wackerNeuson}
              position="QA"
              personPlaceholder={aleksandarPersonPlaceholder}
            />
          </StyledTestimonial>
        </Cards>
      </Container>
    </>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 4rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding-top: 5rem;

  @media (max-width: 820px) {
    padding-top: 3rem;
  }
`;

const StyledTestimonial = styled.div`
  flex: 1 1 calc(33.333% - 1rem);
  box-sizing: border-box;
  max-width: 50%;

  @media (max-width: 768px) {
    flex: 1 1 calc(50%);
    max-width: 100%;
  }

  @media (max-width: 480px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const Description = styled.h5`
  color: #747474;
  justify-content: center;
  margin: auto;
  vertical-align: middle;
  text-align: center;
  width: 65%;
  margin-bottom: 4rem;
  font-weight: bold;
  font-size: 1.3rem;

  line-height: 2rem;

  @media (max-width: 820px) {
    width: 85%;
  }

  background-image: linear-gradient(
    -100deg,
    rgba(211, 255, 255, 0.1),
    rgba(0, 240, 208, 0.5) 60%,
    rgba(108, 227, 255, 0.8)
  );
`;
