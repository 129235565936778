import { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { Tooltip, Typography, Zoom } from "@mui/material";
import { getUser } from "../../../utils";
import {
  getByTaskIdAndUserId,
  getByTypeAndUserId,
} from "../../services/api/LearningApi";
import { getExecutionsCountByTaskId } from "../../services/api/ExecutionApi";
import { BreadcrumbNavigation } from "../breadcrumb-navigation/Breadcrumb-Navigation";

import { useNavigate, useParams } from "react-router-dom";
import { LevelDifficulty } from "../level-difficulty/LevelDifficulty";
import styled from "@emotion/styled";
import { EditorTask } from "./EditorTask";
import { EditorUtils } from "./EditorUtils";
import { AceEditorWrapper } from "./AceEditorWrapper";
import { Button } from "@mui/material";
import _ from "lodash";
import LoadingPage from "../LoadingPage";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { useMount, useSetState } from "react-use";
import a11yChecker from "a11y-checker";
import { State } from "../../models/JoyrideStateModel";
import rocketImg from "../../../../assets/images/rocket.svg";

enum Direction {
  Next = "next",
  Previous = "previous",
}

function Editor(props: any) {
  const [task, setTask] = useState({
    lang: "javascript",
    code: "",
    taskId: "",
    userId: "",
  });
  const [question, setQuestion] = useState({
    _id: "",
    title: "",
    question: "",
    description: "",
    oursolution: "",
    type: "",
    group: "",
    level: "",
    initialcode: "",
    restApiDocs: "",
    testingAppUrl: "",
    howto: "",
  });
  const [counter, setCounter] = useState(0);
  const [staticContent, setStaticContent] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [previousIndex, setPreviousIndex] = useState("");
  const [nextIndex, setNextIndex] = useState("");

  const [{ run, steps }, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: (
          <>
            <b>
              <h5>Welcome to our Editor experience!</h5>
              Would you like to have a get-started tour?
            </b>
          </>
        ),
        placement: "center",
        target: "body",
      },
      {
        content: (
          <>
            <h5>
              <b>Inside the Task tab:</b>
            </h5>
            <h6>You will find your assessment and the expected outcome.</h6>
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: ".task-tab-step",
      },
      {
        content: (
          <>
            <h5>
              <b>Inside the Description tab:</b>
            </h5>
            You will find a detailed explanation for one or multiple operations
            necessary to complete your assessment.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: ".description-tab-step",
      },
      {
        content: (
          <>
            <h5>
              <b>Inside the Add Your Solution tab:</b>
            </h5>
            Here, you will type code examples learned from the Description tab.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: ".add-your-solution-tab-step",
      },
      {
        content: (
          <>
            <h5>
              <b>When your solution is ready:</b>
            </h5>
            Click the "Run" button to get an immediate response.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 15,
        target: "#run-button-step",
      },
      {
        content: (
          <>
            <h5>
              <b>If you get stuck with the assessment:</b>
            </h5>
            Inside the Discussions tab, you can ask questions or check current
            answers.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: ".discussions-tab-step",
      },
      {
        content: (
          <>
            <h5>
              <b>If there is no answer to your question:</b>
            </h5>
            Inside the Our Solution tab you will find a solution to your
            problem. Check it, rewrite yours, run your solution again, and now
            works as expected.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        target: ".our-solution-tab-step",
      },
      {
        content: (
          <>
            <h5>
              <b>When your solution is ready and works as expected:</b>
            </h5>
            Click the "Submit" button to complete your assessment.
          </>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 15,
        target: "#submit-button-step",
      },
      {
        content: (
          <h5>
            Thank you for your patience. Wishing you a pleasant learning day.
          </h5>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 20,
        placement: "center",
        target: "body",
      },
    ],
  });

  const joyrideOptions = {
    steps,
    locale: {
      last: "Finish tour", // Customize the text for the last button
      skip: "Close", // Customize the label for "Skip" to "Close"
    },
  };

  const { id } = useParams();
  const { type } = useParams();

  let taskId = id as string;
  let taskType = type as string;
  let navigate = useNavigate();

  let userId = JSON.parse(getUser() as string)?._id;

  useEffect(() => {
    if (taskId) {
      if (userId) {
        setTaskDetails(taskId, userId);
      }

      getExecutionsCountByTaskId(taskId).then((currentCounter: number) => {
        setCounter(currentCounter);
      });
    }
  }, []);

  useEffect(() => {
    getByTypeAndUserId(taskType, userId).then((staticContent: any) => {
      setStaticContent(staticContent);
      setPreviousAndNextTaskIndex(staticContent);
    });
  }, [taskId]);

  const updateCode = (code: string) => {
    setTask({
      ...task,
      code,
    });
  };

  const setTaskDetails = (taskId: string, userId: string) => {
    setIsLoading(true);
    getByTaskIdAndUserId(taskId, userId)
      .then(
        (currentQuestion: {
          _id: string;
          type: any;
          description: any;
          oursolution: any;
          group: any;
          level: any;
          initialcode: any;
          title: any;
          question: any;
          restApiDocs: any;
          testingAppUrl: any;
          howto: any;
        }) => {
          console.log("Get from API: " + currentQuestion._id);
          setQuestion({
            ...question,
            _id: currentQuestion._id,
            type: currentQuestion.type,
            description: currentQuestion.description,
            oursolution: currentQuestion.oursolution,
            group: currentQuestion.group,
            level: currentQuestion.level,
            initialcode: currentQuestion.initialcode,
            title: currentQuestion.title,
            question: currentQuestion.question,
            restApiDocs: currentQuestion.restApiDocs,
            testingAppUrl: currentQuestion.testingAppUrl,
            howto: currentQuestion.howto,
          });

          setTask({
            ...task,
            code: currentQuestion.initialcode,
            lang: currentQuestion.type,
            userId: userId,
          });

          setIsLoading(false);
        }
      )
      .catch(() => setIsLoading(false));
  };

  const updateCounter = (counter: number) => {
    setCounter(counter);
  };

  const setPreviousAndNextTaskIndex = (staticContent: any) => {
    let staticContentLength = staticContent.length;
    let previousIndex = "";
    let nextIndex = "";
    let currentTaskIndex = getCurrentTaskIndex(staticContent);

    if (currentTaskIndex === 0) {
      nextIndex = staticContent[currentTaskIndex + 1]._id;
      setNextIndex(nextIndex);
      setPreviousIndex("");
    } else if (currentTaskIndex === staticContentLength - 1) {
      previousIndex = staticContent[currentTaskIndex - 1]._id;
      setPreviousIndex(previousIndex);
      setNextIndex("");
    } else {
      previousIndex = staticContent[currentTaskIndex - 1]._id;
      nextIndex = staticContent[currentTaskIndex + 1]._id;
      setNextIndex(nextIndex);
      setPreviousIndex(previousIndex);
    }
  };

  const getCurrentTaskIndex = (staticContent: any) => {
    return _.findIndex(
      staticContent,
      (content: { _id: string }) => content._id === taskId
    );
  };

  const getTask = (direction: Direction) => {
    let staticContentLength = staticContent.length;
    let currentTaskIndex = getCurrentTaskIndex(staticContent);

    if (
      direction === Direction.Previous &&
      currentTaskIndex !== 0 &&
      previousIndex &&
      userId
    ) {
      setTaskDetails(previousIndex, userId);
      getExecutionsCountByTaskId(previousIndex).then(
        (currentCounter: number) => {
          setCounter(currentCounter);
        }
      );
      navigate(`/editor/${previousIndex}/${taskType}`);
    } else if (
      direction === Direction.Next &&
      currentTaskIndex !== staticContentLength - 1 &&
      nextIndex &&
      userId
    ) {
      setTaskDetails(nextIndex, userId);
      getExecutionsCountByTaskId(nextIndex).then((currentCounter: number) => {
        setCounter(currentCounter);
      });
      navigate(`/editor/${nextIndex}/${taskType}`);
    }
  };

  useMount(() => {
    a11yChecker();
  });

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setState({
      run: true,
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Joyride
        {...joyrideOptions}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <PageContent>
        <div className="d-flex justify-content-between align-items-center">
          <StyledBreadcrumbNavigation>
            <BreadcrumbNavigation title={taskType}></BreadcrumbNavigation>
          </StyledBreadcrumbNavigation>
          <Tooltip title="Coming soon" TransitionComponent={Zoom} arrow={true}>
            <GuideButton onClick={handleClickStart} disabled={true}>
              Start guided editor tour
              <StyledRocket
                src={rocketImg}
                onClick={handleClickStart}
                height="40"
              />
            </GuideButton>
          </Tooltip>
        </div>
        <PageWrapper>
          <ContainerHeader>
            <LevelAndTitle>
              {+question.level === 1 && <LevelDifficulty level="Easy" />}
              {+question.level === 2 && <LevelDifficulty level="Medium" />}
              {+question.level === 3 && <LevelDifficulty level="Hard" />}
              {+question.level === 4 && <LevelDifficulty level="Very hard" />}
              <Title>{question.title}</Title>
            </LevelAndTitle>

            <Executions>
              <ButtonsWrapper>
                <Button
                  className="task-direction-buttons"
                  variant="contained"
                  size="medium"
                  onClick={() => getTask(Direction.Previous)}
                  disabled={previousIndex === ""}
                >
                  &lt; Previous task
                </Button>

                <Button
                  className="task-direction-buttons"
                  variant="contained"
                  size="medium"
                  onClick={() => getTask(Direction.Next)}
                  disabled={nextIndex === ""}
                >
                  Next task &gt;
                </Button>
              </ButtonsWrapper>
              <ExecutionAndCounter className="d-*-flex">
                Total executions: &nbsp; <Counter>{counter}</Counter>
              </ExecutionAndCounter>
            </Executions>
          </ContainerHeader>

          <StyledContainer>
            <Row>
              {["mocharestapi", "unittestrestapi", "protractor", "testcase"].includes(taskType) ? (
                <>
                  <Col md={4}>
                    <EditorTask taskType={taskType} question={question} />
                  </Col>
                  <Col md={4}>
                    <EditorUtils taskType={taskType} question={question} />
                  </Col>
                  <StyledCol md={4}>
                    <AceEditorWrapper
                      task={task}
                      updateCode={updateCode}
                      updateCounter={updateCounter}
                      question={question}
                    />
                  </StyledCol>
                </>
              ) : (
                <>
                  <Col md={6}>
                    <EditorTask taskType={taskType} question={question} />
                  </Col>
                  <StyledCol md={6}>
                    <AceEditorWrapper
                      task={task}
                      updateCode={updateCode}
                      updateCounter={updateCounter}
                      question={question}
                    />
                  </StyledCol>
                </>
              )}
            </Row>
          </StyledContainer>

        </PageWrapper>
      </PageContent>
    </>
  );
}

export default Editor;

const PageContent = styled.div`
  background: #f6f9fc;
  padding: 1rem 3rem 2rem 3rem;

  @media (max-width: 1200px) {
    padding: 1rem 0.5rem;
  }
`;

const PageWrapper = styled.div`
  box-shadow: 0 9px 30px rgb(0 0 0 / 0.2);
  border-radius: 10px;
`;

const StyledBreadcrumbNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  nav {
    ol {
      margin: 0;
    }
  }
`;

const StyledContainer = styled(Container)`
  width: 100%;
  max-width: 100%;
  padding: 0 3rem;
  @media (max-width: 800px) {
    height: auto;
    padding: 0 0.5rem;
  }
  @media (max-width: 1000px) {
    height: auto;
    padding: 0 1rem;
  }
`;

const ContainerHeader = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px 10px 0 0;
  flex-wrap: wrap;
`;

const LevelAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: capitalize;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 767px) {
    font-size: 1.1rem;
  }
`;

const Counter = styled(Typography)`
  font-weight: bold;
  font-size 1.5rem;
`;

const Executions = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  flex-wrap: wrap;
  gap: 0;
`;

const StyledCol = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 3rem;
  }
`;

const ButtonsWrapper = styled(Button)`
  gap: 1rem;
  display: flex;
  padding-left: 0;

  @media (max-width: 685px) {
    margin-top: 0.5rem;
  }
`;

const ExecutionAndCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GuideButton = styled(Button)`
  background-image: linear-gradient(
    to top right,
    ${(props: any) => props.theme.palette.primary.main},
    ${(props: any) => props.theme.palette.secondary.main}
  );
  text-transform: none;
  color: #ffffff;
  height: auto;
  line-height: normal;
  font-size: 17px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  cursor: pointer;
  float: right;
  z-index: 990;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 820px) {
    right: 0rem;
  }

  &:disabled {
    color: white;
    background: lightgray;
    pointer-events: auto;
  }
`;

const StyledRocket = styled.img`
  display: inline-block;
  animation: rocketAnimation 1.3s infinite alternate; /* Adjust the animation duration as needed */

  @keyframes rocketAnimation {
    from {
      transform: translateY(0); /* Start position */
    }
    to {
      transform: translateY(3px); /* End position */
    }
  }
`;
