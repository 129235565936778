import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Iframe from "react-iframe";
import { ReactPainter } from "react-painter";
import styled from "@emotion/styled";
import { AddCommentForm } from "./AddCommentForm";
import { getUser } from "../../../utils";
import { ContainedButton } from "../buttons/ContainedButton";
import cards from "../../../../assets/images/credit-cards.svg";
import { Mixpanel } from "../../../../../Mixpanel";
import MarkdownWithHighlight from "../markdown-grid/MarkdownWithHighlight";
import MarkdownView from "react-showdown";

interface QuestionModel {
  howto: string;
  description: string;
  group: string;
  initialcode: string;
  level: string;
  oursolution: string;
  question: string;
  restApiDocs: string;
  testingAppUrl: string;
  title: string;
  type: string;
  _id: string;
}

interface EditorUtilsProps {
  taskType: string;
  question: QuestionModel;
}

export const EditorUtils = (props: EditorUtilsProps) => {
  const { taskType, question } = props;
  const userType = JSON.parse(getUser() as string)?.type;

  Mixpanel.track(
    "Editor Util opened - task type:" +
    taskType +
    " - for question: " +
    question.question
  );

  return (
    <Tabs className="unselectable">
      <TabList>

        {taskType === "mocharestapi" && <Tab>RestApi Docs</Tab>}

        {taskType === "unittestrestapi" && <Tab>RestApi Docs</Tab>}

        {taskType === "protractor" && <Tab>TestingApp</Tab>}

        {taskType === "testcase" && <Tab>TestingApp</Tab>}
      </TabList>

      {taskType === "mocharestapi" && (
        <TabPanel className="tab-height">
          <div>
            <Iframe
              url={question.restApiDocs}
              width="100%"
              height="600px"
              id="myId"
              className="myClassname"
              display="block"
              position="relative"
              scrolling="auto"
            />
          </div>
        </TabPanel>
      )}

      {taskType === "unittestrestapi" && (
        <TabPanel className="tab-height">
          <div>
            <Iframe
              url={question.restApiDocs}
              width="100%"
              height="600px"
              id="myId"
              className="myClassname"
              display="block"
              position="relative"
              scrolling="auto"
            />
          </div>
        </TabPanel>
      )}

      {taskType === "protractor" && (
        <TabPanel className="tab-height">
          <div>
            <Iframe
              url={question.testingAppUrl}
              width="100%"
              height="600px"
              id="myId"
              className="myClassname"
              display="block"
              position="relative"
              scrolling="auto"
            />
          </div>
        </TabPanel>
      )}

      {taskType === "testcase" && (
        <TabPanel className="tab-height">
          <div>
            <Iframe
              url={question.testingAppUrl}
              width="100%"
              height="600px"
              id="myId"
              className="myClassname"
              display="block"
              position="relative"
              scrolling="auto"
            />
          </div>
        </TabPanel>
      )}
    </Tabs>
  );
};

const StyledReactPainter = styled.div`
  background: #ffffff;
`;

const FreeUsersMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
`;
