import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Testimonial } from "./Testimonial";
import { useTheme } from "@mui/material";
import styled from "@emotion/styled";

import quantox from "../../../../assets/images/companies/Quantox-Technology-logo.png";
import wackerNeuson from "../../../../assets/images/companies/Wacker-Neuson-logo.png";
import foodtec from "../../../../assets/images/companies/foodtec.jpg";

import djemboPersonPlaceholder from "../../../../assets/images/review-persons/djembo.jpeg";
import aleksandarPersonPlaceholder from "../../../../assets/images/review-persons/aleksandar.jpg";
import anastasiosPersonPlaceholder from "../../../../assets/images/review-persons/anastasios.jpg";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import { AnimationOnScroll } from "react-animation-on-scroll";

const responsive = {
  480: { items: 1 },
  1000: { items: 2 },
  1500: { items: 3 },
};

const items = [
  <Testimonial
    name="Stefan"
    testimonial={
      <>
        <p>
          QASkills appeared at a time when I was a bit tired of reading and
          watching different QA-related courses, which all looked similar, and
          none of them gave me a true vision of what it takes to learn, so I can
          dare to apply for the real QA position.
        </p>
        <p>
          I needed a different approach, and the QASkills concept seemed like
          the perfect match for me as I was searching for a single place where I
          could learn and practically check and track what I've learned right
          away.
        </p>
        <p>
          Going through the flow of QASkills lessons and tasks, I was getting
          the knowledge much easier, and I got from zero to ready at no time.
        </p>
        <p>I passed the interview and started working as a junior QA.</p>
        <p>
          I was surprised at how essential the lessons from the QASkills were
          for actual tasks at work.
        </p>
        I'm writing this to say thank you, guys, and of course, I would highly
        recommend QASkills to all the future and current QA's.
      </>
    }
    companyLogo={quantox}
    position="Junior QA Engineer"
    count={1}
    personPlaceholder={djemboPersonPlaceholder}
  />,
  <Testimonial
    name="Anastasios"
    testimonial={
      <>
        <p>
          QASkills helped me in my early journey towards becoming a Software QA
          engineer.
        </p>
        <p>
          It integrates both theoretical and practical knowledge, guiding you
          through writing test cases, conducting API testing, improving your
          coding skills, mastering SQL queries, and much more.
        </p>
        These essentials collectively prepare you for a successful career in QA
        testing.
      </>
    }
    companyLogo={foodtec}
    position="Software QA engineer"
    count={1}
    personPlaceholder={anastasiosPersonPlaceholder}
  />,
  <Testimonial
    name="Aleksandar"
    testimonial="Fantastic Platform for Beginners and Juniors QA Testers! ⭐⭐⭐⭐⭐"
    companyLogo={wackerNeuson}
    position="QA"
    count={1}
    personPlaceholder={aleksandarPersonPlaceholder}
  />,
];

export const TestimonialsCarousel = () => {
  const theme = useTheme();

  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__zoomInDown">
        <Title>We already helped them...</Title>
      </AnimationOnScroll>

      <AliceCarousel
        items={items}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls
        touchTracking={false}
        autoPlay={items.length >= 2}
        autoPlayInterval={3000}
        infinite={true}
        renderPrevButton={() => {
          return <ArrowCircleLeftOutlinedIcon className="pointer arrow-icon" />;
        }}
        renderNextButton={() => {
          return (
            <ArrowCircleRightOutlinedIcon className="pointer arrow-icon" />
          );
        }}
      />
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  padding: 2rem 1rem 1rem 1rem;
  font-weight: bold;
`;
