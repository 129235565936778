import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";
import styled from "@emotion/styled";
import { PurchaseCard } from "./PurchaseCard";
import yearlyPlus from "../../../assets/images/purchase/yearly-plus.svg";
import monthlyPlus from "../../../assets/images/purchase/monthly-plus.svg";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getBeServerEndpoint, getPlatformEndpoint } from "../../../config";
import { toast } from "react-hot-toast";
import LoadingPage from "../../shared/components/LoadingPage";
import {
  PackageType,
  UserInfoDto,
} from "../../roadmap-and-progress/RoadmapAndProgress";
import { Mixpanel } from "../../../../Mixpanel";
import { getUser } from "../../utils";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Zoom,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginButton } from "../../shared/components/buttons/LoginButton";
import { FreeAccessCard } from "./FreeAccessCard";
import { DiscountTabs } from "./DiscountTabs";
import { CalculatedSavings } from "./CalculatedSavings";
import shoppingCart from "../../../assets/images/purchase/shopping-cart.svg";
import { checkIfGuestExists } from "../../utils/guestCheck";

export interface PackagesModel {
  type: string;
  amount: number | string;
  discount: number | string;
  amountRsd: number | string;
  saving: number | string;
}

interface PaymentModel {
  userId: string;
  packageType: string;
  paymentFormUrl: string;
}

export const PurchasePage = (props: { history: string[] }) => {
  const itemsFree = [
    "38 different issues for you to solve",
    "Test in 3 different frameworks",
    "Written solutions in 2 languages",
    "Work in 3 execution environments",
  ];

  const items = [
    "150+ different issues for you to solve",
    "Test in 3 different frameworks",
    "Written solutions in 2 languages",
    "Work in 3 execution environments",
    "Get certificate of completion",
  ];

  const itemsAndFinalTest = [
    "150+ different issues for you to solve",
    "Test in 3 different frameworks",
    "Written solutions in 2 languages",
    "Work in 3 execution environments",
    "Get certificate of completion",
    "Final test and video call interview",
  ];

  const packagesUrl = "/packages";
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState<PackagesModel[]>([]);
  const [activePackage, setActivePackage] = useState<string | undefined>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(!isLoggedIn);
  const [promoCode, setPromoCode] = useState<string>("");

  const navigate = useNavigate();
  const packagesRef = useRef<null | HTMLDivElement>(null);
  const location = useLocation();

  const disabledPurchaseMessage =
    "You need to select one of the packages and to agree to our Terms and Conditions and Privacy Policy.";

  const errorMessage409 =
    "Please wait a moment, your transaction is in the process of confirmation.";

  let userDetails = {} as any;
  if (isLoggedIn) {
    const currentUser = getUser() as string;

    if (currentUser) {
      userDetails = JSON.parse(currentUser);
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo(userDetails._id);
    }
    getPackages();
  }, []);

  useEffect(() => {
    if (location?.state?.path === "packages") {
      if (packagesRef.current) {
        setTimeout(() => {
          packagesRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
    }
  }, [location?.state?.path]);

  const getPackages = () => {
    setIsLoading(true);
    axios
      .get<PackagesModel[]>(getPlatformEndpoint() + packagesUrl, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setPackages(response?.data);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });
  };

  const getUserInfo = (userId: string) => {
    setIsLoading(true);
    axios
      .get<UserInfoDto>(getBeServerEndpoint() + `/users/${userId}`, {
        headers: {
          Authorization: "Basic dGVzdDp0ZXN0",
          "content-type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setActivePackage(response.data?.activePackage);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });
  };

  const startPaymentProccess = () => {
    setIsLoading(true);
    Mixpanel.track("Purchase package opened: " + selectedPackage);
    axios
      .post<PaymentModel>(
        getPlatformEndpoint() + `/transactions`,
        {
          userId: userDetails?._id,
          packageType: selectedPackage,
          promoCode: promoCode ? promoCode : "",
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.paymentFormUrl) {
          navigate("/payment-form", {
            replace: true,
            state: response?.data?.paymentFormUrl,
          });
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);

        if (error.response.data.status === 409) {
          throw new Error(errorMessage409);
        } else {
          if (error?.message) {
            throw new Error(error);
          }
        }
      });
  };

  const handleClose = () => {
    setIsShowLogin?.(false);
  };

  const checkActivePackageMessage = () => {
    if (activePackage === PackageType.monthlyPackage) {
      return "month";
    } else if (activePackage === PackageType.yearlyPackage) {
      return "year";
    } else {
      return "year and final test";
    }
  };

  const handleDataFromPromoCode = (data: PackagesModel[]) => {
    setPackages(data);
  };

  const getPromoCode = (code: string) => {
    setPromoCode(code);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  Mixpanel.track("Purchase page opened");

  return (
    <Container>
      <PageIntro
        description={
          <>
            <p>
              You’ve spent too long in a role that doesn’t make your feel
              satisfied.
            </p>
            <p>
              <b>
                It’s time to turn a new page in a career that’s rewarding and
                fulfilling.
              </b>
            </p>
            Check out some of our examples to see how we can help you achieve a
            new profession as a QA engineer.{" "}
            <p>
              <b>
                With your dedication and our expertise, you can have everything
                you need to join the IT field as a new professional.
              </b>
            </p>
          </>
        }
        subtitle="Your new career in IT begins today!"
        title="Purchase"
        showContainedButton={false}
      />
      <br />
      <VerticalLine />

      {activePackage &&
      activePackage !== "" &&
      userDetails?.type !== PackageType.freeAccess ? (
        <ActivePackageMessage>
          Thank you for purchasing our 1-{checkActivePackageMessage()} access.{" "}
          <br />
          We can’t wait for you to try your QASkills and tell us what you think.
        </ActivePackageMessage>
      ) : (
        ""
      )}

      <CardsContainer ref={packagesRef}>
        <FreeAccessCard
          accessPeriod="free access"
          amount={0}
          amountRsd={0}
          currency="&euro;"
          items={itemsFree}
          color="#FDD835"
          icon={monthlyPlus}
        />
        <PurchaseCard
          activePackage={activePackage}
          accessPeriod="1 month access"
          amount={
            packages[0]?.type === PackageType.monthlyPackage
              ? packages[0]?.amount
              : ""
          }
          amountRsd={packages[0]?.amountRsd}
          discount={
            packages[0]?.type === PackageType.monthlyPackage
              ? packages[0]?.discount
              : ""
          }
          currency="&euro;"
          items={items}
          icon={monthlyPlus}
          color="secondary"
          packageType={
            packages[0]?.type === PackageType.monthlyPackage
              ? packages[0]?.type
              : ""
          }
          setSelectedPackage={setSelectedPackage}
        />
        <PurchaseCard
          activePackage={activePackage}
          accessPeriod="1 year access"
          amount={
            packages[1]?.type === PackageType.yearlyPackage
              ? packages[1]?.amount
              : ""
          }
          amountRsd={packages[1]?.amountRsd}
          discount={
            packages[1]?.type === PackageType.yearlyPackage
              ? packages[1]?.discount
              : ""
          }
          currency="&euro;"
          items={items}
          color="primary"
          icon={yearlyPlus}
          packageType={
            packages[1]?.type === PackageType.yearlyPackage
              ? packages[1]?.type
              : ""
          }
          setSelectedPackage={setSelectedPackage}
        />
        <PurchaseCard
          activePackage={activePackage}
          accessPeriod="1 year access + final test"
          amount={
            packages[2]?.type === PackageType.yearlyPackageAndFinal
              ? packages[2]?.amount
              : ""
          }
          amountRsd={packages[2]?.amountRsd}
          discount={
            packages[2]?.type === PackageType.yearlyPackageAndFinal
              ? packages[2]?.discount
              : ""
          }
          currency="&euro;"
          items={itemsAndFinalTest}
          icon={yearlyPlus}
          color={"#6C2DC7"}
          packageType={
            packages[2]?.type === PackageType.yearlyPackageAndFinal
              ? packages[2]?.type
              : ""
          }
          setSelectedPackage={setSelectedPackage}
        />
      </CardsContainer>

      <PurchasePartWrapper id="purchase-part">
        <PurchasePart customcolor={selectedPackage}>
          {!isLoggedIn || checkIfGuestExists() ? (
            <>
              <Typography variant="body2" align="center">
                To proceed with a purchase, you must be signed in.
              </Typography>
              <ButtonWrapper>
                <LoginButton shouldClosePopup={handleClose} />
              </ButtonWrapper>
            </>
          ) : (
            <>
              <DiscountTabs
                isChecked={isChecked}
                isLoggedIn={isLoggedIn}
                onDataFromPromoCode={handleDataFromPromoCode}
                sendPromoCode={getPromoCode}
                selectedPackage={selectedPackage}
              ></DiscountTabs>

              {selectedPackage ? (
                <CalculatedSavings
                  promoCode={promoCode}
                  packages={packages}
                  selectedPackage={selectedPackage}
                ></CalculatedSavings>
              ) : (
                ""
              )}

              <StyledFormControlLabel
                control={
                  <StyledCheckbox
                    customcolor={selectedPackage}
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    disabled={activePackage === "YEARLY_PACKAGE_AND_FINAL_TEST"}
                  />
                }
                label={
                  <Typography variant="caption">
                    I have read and agree with{" "}
                    <a href="/terms-of-use">Terms and Conditions</a> and{" "}
                    <a href="/privacy-policy">Privacy Policy</a>.
                  </Typography>
                }
              />

              <PurchaseButtonWrapper>
                <Tooltip
                  title={
                    selectedPackage == "" ||
                    (selectedPackage !== PackageType.monthlyPackage &&
                      selectedPackage !== PackageType.yearlyPackage &&
                      selectedPackage !== PackageType.yearlyPackageAndFinal) ||
                    !isLoggedIn ||
                    !isChecked
                      ? disabledPurchaseMessage
                      : ""
                  }
                  TransitionComponent={Zoom}
                  arrow={true}
                >
                  <span>
                    <PurchaseButton
                      customcolor={selectedPackage}
                      disabled={
                        selectedPackage == "" ||
                        (selectedPackage !== PackageType.monthlyPackage &&
                          selectedPackage !== PackageType.yearlyPackage &&
                          selectedPackage !==
                            PackageType.yearlyPackageAndFinal) ||
                        !isLoggedIn ||
                        !isChecked
                      }
                      onClick={startPaymentProccess}
                    >
                      <div className="d-flex justify-content-around">
                        <img
                          src={shoppingCart}
                          alt="shopping cart"
                          width="30"
                        />
                        <Typography variant="h6">Purchase</Typography>
                      </div>
                    </PurchaseButton>
                  </span>
                </Tooltip>
              </PurchaseButtonWrapper>
            </>
          )}
        </PurchasePart>
      </PurchasePartWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 2rem;
  padding-right: 2rem;

  @media (max-width: 1368px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 5rem;
  padding-top: 3rem;
  gap: 5rem 3rem;

  @media (max-width: 1600px) {
    gap: 6rem;
  }

  @media (max-width: 1159px) {
    gap: 6rem;
  }
`;

const PurchasePart = styled.div<{ customcolor: string }>`
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 5rem;
  padding: 2rem;
  background: #ffffff;

  border: 4px solid
    ${(props: any) =>
      props.customcolor === PackageType.yearlyPackageAndFinal
        ? "#6C2DC7"
        : props?.customcolor === PackageType.monthlyPackage
        ? props.theme.palette.secondary.main
        : props?.customcolor === PackageType.yearlyPackage
        ? props.theme.palette.primary.main
        : "transparent"};

  @media (max-width: 1159px) {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  }

  @media (max-width: 800px) {
    margin: 0.5;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 1rem auto 1rem auto;
  line-height: 1rem;
  & .MuiTypography-root {
    line-height: 1.3rem;
  }
`;

const PurchaseButton = styled(Button)<{ customcolor: string }>`
  background: ${(props: any) =>
    props.customcolor === PackageType.yearlyPackageAndFinal
      ? "#6C2DC7"
      : props.customcolor === PackageType.monthlyPackage
      ? props.theme.palette.secondary.main
      : props?.customcolor === PackageType.yearlyPackage
      ? props.theme.palette.primary.main
      : "default"};
  color: #ffffff;
  border: none !important;
  width: 14rem;
  height: 90px;
  border-radius: 10px;
  z-index: 1;
  display: block;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 25px #ababab;
    background: ${(props: any) =>
      props.customcolor === PackageType.yearlyPackageAndFinal
        ? "#6C2DC7"
        : props.customcolor === PackageType.monthlyPackage
        ? props.theme.palette.secondary.main
        : props?.customcolor === PackageType.yearlyPackage
        ? props.theme.palette.primary.main
        : "default"};
  }

  :disabled {
    background: gray !important;
    pointer-events: none;
    border: none !important;
    animation: none;
  }
`;

const PurchasePartWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

const StyledCheckbox = styled(Checkbox)<{ customcolor: string }>`
  &.MuiButtonBase-root {
    color: ${(props: any) =>
      props.customcolor === PackageType.yearlyPackageAndFinal
        ? "#6C2DC7"
        : props.customcolor === PackageType.monthlyPackage
        ? props.theme.palette.secondary.main
        : props?.customcolor === PackageType.yearlyPackage
        ? props.theme.palette.primary.main
        : "default"};
  }
  :disabled {
    pointer-events: auto;
  }
`;

const ButtonWrapper = styled.div`
  margin: auto;
  display: block;
  margin-top: 1rem;
`;

const ActivePackageMessage = styled.h3`
  width: fit-content;
  text-align: center;
  margin: 2rem auto 2rem auto;
  padding: 0.5rem;
  background-image: linear-gradient(
    -100deg,
    rgba(211, 255, 255, 0.1),
    rgba(0, 240, 208, 0.5) 60%,
    rgba(108, 227, 255, 0.8)
  );
  text-shadow: 2px 2px 10px rgba(101, 101, 101, 1);
`;

const PurchaseButtonWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items; center;
  position: relative;
`;
