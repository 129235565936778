import styled from "@emotion/styled";
import { TeamCard } from "./TeamCard";
import personLeft from "../../../assets/images/terza.jpeg";
import personRight from "../../../assets/images/sasa.jpeg";
import personLeftDown from "../../../assets/images/nenad.jpeg";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const TeamPage = () => {
  return (
    <Container>
      <PageIntro
        description={
          <>
            <p>
              The QASkills team is your new squad for helping you become a
              qualified and high-earning QA engineer.
            </p>
            <p>
              All members of our team have been hand-picked, carefully vetted,
              and trained to ensure our customers receive effective and
              top-notch services.
            </p>
            <p>
              We like to joke that QASkills was founded just so we could help
              developers prepare for technical interviews instead of doing it
              ourselves. All joking aside, we have the professionalism,
              expertise, and experience to support you in achieving your goals
              in the IT industry.
            </p>
          </>
        }
        subtitle="We’re dedicated to your success."
        title="Team"
        showContainedButton={true}
      />
      <StyledVerticalLine />

      <TeamTitle>
        Meet the masterminds behind <br /> QASkills
      </TeamTitle>

      <TeamCard
        description={
          <>
            <p>
              Nenad is experienced Software Engineer with a demonstrated history
              of working in the information technology and payments industry.
            </p>
            Skilled in Java, Agile, and software development in general. From
            gathering requirements until final implementation and verification.
          </>
        }
        icon={personLeft}
        name="Nenad Terzic"
        position="Co-Founder / Software engineer"
        cardPosition="left"
        linkedinLink="https://www.linkedin.com/in/nenad-terzic-77a85730/"
      />

      <TeamCard
        description={
          <>
            <p>
              Saša is a Senior Software Engineer and QA Architect with a
              demonstrated history of working in the information technology and
              services industry.
            </p>
            <p>
              With ten years of experience in software development, including
              developer, QA, DevOps, and performance engineer positions, he
              worked for multiple clients in Banking & Finance Industries.
            </p>
            Constantly working on updating and improving skills about
            architecture, process engineering, continuous deployment, continuous
            integration and coaching & training.
          </>
        }
        icon={personRight}
        name="Sasa Starcevic"
        position="Co-Founder / Software engineer"
        cardPosition="right"
        linkedinLink="https://www.linkedin.com/in/sasa-starcevic/"
      />

      <TeamCard
        description={
          <>
            <p>
              Nenad is passionate front-end developer with many years of
              expertise and successful projects.
            </p>
            <p>
              Proficient in a range of JavaScript frameworks, including Angular
              and React, he leverages modern tools and methodologies to deliver
              innovative software solutions.
            </p>
            Open-minded, detail-oriented, and focused toward the quality of web
            applications, user interfaces, and user experiences.
          </>
        }
        icon={personLeftDown}
        name="Nenad Mijailovic"
        position="Co-Founder / Software engineer"
        cardPosition="left"
        linkedinLink="https://www.linkedin.com/in/nenadmijailovic/"
      />
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 970px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const TeamTitle = styled.h3`
  text-align: center;
  font-weight: bold;
  padding-top: 6rem;
`;

const StyledVerticalLine = styled(VerticalLine)``;
