import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const Faq = () => {
  return (
    <Container>
      <PageIntro
        description="If you have a question or are experiencing an issue, look around through our FAQ below."
        subtitle="Frequently Asked Questions"
        title=""
      />
      <br />
      <VerticalLine />

      <StyledAccordionFirst>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>How To</h5>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h6>What's the best way to use QASkills.io?</h6>
                </Accordion.Header>
                <Accordion.Body>
                  It would be best if you went through the next section in the
                  following order: QASkills Fundamentals, Development
                  Methodologies, Coding Skills, Automation Tasks, Database
                  Tasks, Linux Interaction Tasks, Automation Infra Fundamentals,
                  Automation Infra Tasks, Soft Skills Fundamentals, and last but
                  not least How To Write a CV.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h6>
                    What programming languages does the QASkills.io coding
                    workspace support?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Our coding workspace currently supports JavaScript and
                    Python.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h6>
                    Will you be adding more programming languages in the future?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    While we will add a few more programming languages over
                    time, our current selection should be generally enough for
                    now.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h6>Are there any prerequisites to using QASkills.io?</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Yes, but very few. You need to be dedicated, persistent and
                    willing to learn new material and thus you will
                    significantly increase your chances at the next interview.
                    You will learn how to write basic code samples in Javascript
                    and Python. Our platform will help you with automation
                    testing, SQL, Linux commands, and much more.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <h6>
                    I'm a complete beginner in testing and programming. Is
                    QASkills.io for me?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Yes. Our platform will teach you everything you need to know
                    about testing so that you can be prepared for intership /
                    junior / medior QA Engineer/ Tester interview sessions."
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordionFirst>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Purchasing</h5>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h6>
                    Do you offer a free trial period to try out a product?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    All of our sections have some content that's freely
                    available as a means of testing them out and deciding
                    whether they're a good fit. You can browse all of our
                    products to see which freely available content.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h6>
                    How long do I have to wait for the package to be activated
                    after I purchase it?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    After purchasing, your package will be activated within a
                    few minutes.
                    <br />
                    <br />
                    You can check your status in some of the following steps:
                    <br />
                    1. Navigate to the Content -&gt; roadmap and progress
                    <br />
                    &emsp;1.1. Active package description
                    <br />
                    &emsp;&emsp;1.2. Click on the 'here' navigation, which will
                    redirect you to your packages
                    <br />
                    &emsp;&emsp;&emsp;1.3. Your transactions - will open a
                    window with your transaction details
                    <br />
                    2. Click on user profile icon in the header -&gt; choose
                    Account details and repeat steps 1.1. or 1.2. or 1.3.
                    <br />
                    3. Navigate to the Purchase page.
                    <br />
                    &emsp;3.1. As soon as it's activated, you'll receive user
                    message information regarding purchases.
                    <br />
                    &emsp;&emsp;3.2. If monthly package is activated, it will be
                    disabled; if the yearly package is active, both packages
                    will be disabled until the bought time expires.
                    <br />
                    4. Reload the website.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What is your refund policy? We don't offer refunds under any
                  circumstance.
                </Accordion.Header>
                <Accordion.Body>
                  You can try out our free content to decide if you'd like to
                  buy a product. In case of cancellation of the exchange rate or
                  dissatisfaction, the consumer has the right to request a
                  refund if the complaint is submitted within 14 days from the
                  date of payment, and if no more than 1/4 (one quarter) of the
                  exchange rate has been exceeded. It is not possible to deviate
                  from the rules due to the policy on the QASkills platform. The
                  exchange is made for a course in the same price range, cheaper
                  or by paying the difference for a more expensive one. The
                  request is sent electronically to office@qaskills.io.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  After my 1-year access expires, will you automatically renew
                  it and charge me?
                </Accordion.Header>
                <Accordion.Body>
                  No. We don't automatically renew your access, and we certainly
                  don't automatically charge you. If you want to regain access
                  to a product, you'll have to repurchase that product yourself.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Can I get a receipt for my purchase?
                </Accordion.Header>
                <Accordion.Body>
                  You should get a receipt by email for any purchase you make on
                  the platform. You can also download your transaction receipts
                  at any time here."
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Common Issues</h5>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h6>
                    If I purchase a product, how long do I have access to it?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    You'll have access to the product for three months or one
                    full year from your date of purchase, based on the plan you
                    have purchased.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h6>
                    If I've already purchased a product and you add more content
                    on the platform, will I gain access to that content for
                    free?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Any new content will be available to you for free (even if
                    our price increases), so long as your access to the product
                    hasn't expired. For example, new tasks will be available to
                    you for free if you've bought and still own QASkills.io. 3.
                    Can two or more people share a single QASkills.io account?
                    No. As is explained in our Terms and Conditions, you may not
                    assign or otherwise transfer your account to any other
                    person or entity, and you may not share an account with
                    another person.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h6>Will you be adding more content to the platform?</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Yes. We're continually adding content to all sections. Yes.
                    We're continually adding content to all sections. Yes. We're
                    continually adding content to all sections.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h6>Do you offer 1-on-1 coaching or mentoring?</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    No, we currently don't offer 1-on-1 coaching or mentoring.
                    No, we currently don't offer 1-on-1 coaching or mentoring.
                    No, we currently don't offer 1-on-1 coaching or mentoring.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <h6>
                    If I'm stuck on a problem, is there a place where I ask for
                    help?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    No, we currently don't offer a place for you to ask for
                    help. Our platform was designed to minimize the need for
                    outside help as much as possible. Between the description
                    and our solutions, you should have all the resources you
                    need to understand how to solve every problem on the
                    platform.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <h6>
                    Will my account progress remain saved once my access
                    expires?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    Yes. Your account progress will be saved; you'll be able to
                    pick up right where you left off if you decide to purchase a
                    product again in the future. 8. I want to delete my
                    QASkills.io account.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* <Accordion>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <h6>
                    I want to delete my QASkills.io account. How can I do that?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    You can delete your QASkills.io account here
                    <a href="/roadmap-and-progress"> link</a>.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

            <Accordion>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <h6>
                    I've purchased a product, but I'm still told that I need to
                    buy the product when I log in.
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    It hasn't been a year, so I know my access hasn't expired.
                    What's wrong? You're likely logged in with a different
                    account than the one you were logged in with when you
                    purchased a product, or you're not logged in at all. The
                    account that you're logged in with when you purchase a
                    product is the one that your access to the platform is tied
                    to, so make sure you're logged in with that Google account.
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <h6>
                    Can I log in with a username and password instead of a
                    Google account?
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <h6>
                    No, we don't support custom username and password
                    combinations. Our authentication system operates using the
                    OAuth standard and requires that you sign in with a Google
                    account, and this takes advantage of the security and
                    simplicity of Google authentication. Our system never sees
                    or interacts with the password for your Google account."
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Report a Bug / Issue</h5>
          </Accordion.Header>
          <Accordion.Body>
            <h6>
              You can send as Bug Request via <a href="/contactus">link</a>.
            </h6>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Feature Request</h5>
          </Accordion.Header>
          <Accordion.Body>
            <h6>
              You can send as Feature Request via <a href="/contactus">link</a>.
            </h6>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Feedback Request</h5>
          </Accordion.Header>
          <Accordion.Body>
            <h6>
              You can send as Feedback Request via <a href="/feedback">link</a>.
            </h6>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>Contact Us</h5>
          </Accordion.Header>
          <Accordion.Body>
            You can Contact Us via <a href="/contactus">link</a>.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const StyledAccordionFirst = styled(Accordion)`
  margin-top: 3rem;

  @media (min-width: 768px) {
    width: 90%;
    margin: auto;
  }

  @media (min-width: 1200px) {
    width: 60%;
    margin: auto;
  }
`;

const StyledAccordion = styled(Accordion)`
  @media (min-width: 768px) {
    width: 90%;
    margin: auto;
  }

  @media (min-width: 1200px) {
    width: 60%;
    margin: auto;
  }
`;
