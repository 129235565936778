import styled from "@emotion/styled";
import { AnimationOnScroll } from "react-animation-on-scroll";
import TrustpilotWidget from "../../../shared/components/trustpilot-widget/TruspilotWidget";

export const WhatIsQASkills = () => {
  return (
    <>
      <TitleContainer>
        <AnimationOnScroll animateOnce animateIn="animate__bounceIn">
          <Title>What is QASkills?</Title>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce animateIn="animate__tada">
          <StyledHeading4 className="text-center">
            We empower people just like you to land rewarding IT positions as
            Software Quality Assurance Engineers.
          </StyledHeading4>
        </AnimationOnScroll>

        {/* <ButtonWrapper>
          <OutlinedButton
            text="Watch the video"
            variant="outlined"
            disabled={false}
            icon={playVideoIcon}
            color="#EB1F5D"
          />
        </ButtonWrapper> */}

        <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
          <StyledTrustpilotWidget>
            <TrustpilotWidget />
          </StyledTrustpilotWidget>
        </AnimationOnScroll>
      </TitleContainer>

      <StyledVerticalLine />
      <SolutionDescription>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeftBig">
          <div></div>
          <StyledHeading2>What’s the Problem?</StyledHeading2>
          <ProblemAndSolution>
            <p>
              Let’s face it. You’re not feeling challenged or satisfied with
              your current professional career which is probably taking a
              negative toll on your life overall.
            </p>
            <p>
              <b>You don’t just want a job.</b>
              <br />
              <b>You want something rewarding, challenging, and fulfilling.</b>
            </p>
            <p>
              You love technology, you’re not interested in becoming a
              developer. Good news! You don’t have to be a developer to work in
              IT!
            </p>
          </ProblemAndSolution>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce animateIn="animate__fadeInRightBig">
          <StyledHeading2>
            Why <ColoredTitle>You Should Become a QA Engineer</ColoredTitle>
          </StyledHeading2>
          <ProblemAndSolution>
            <p>
              CareerBliss has ranked Quality Assurance position as the second
              happiest job when taking a wide variety of factors into account.
            </p>
            <p>
              <b>
                If you’re wondering what it feels like to wake up every day
                excited to leave for work and end the workday feeling satisfied,
                this career might be calling your name.
              </b>
            </p>
            But we understand how difficult it is to start something new and
            turn a new page in your professional life. That’s why we’ve
            developed QA Skills - a comprehensive interactive platform designed
            to train people to become Quality Assurance Engineers.
          </ProblemAndSolution>
        </AnimationOnScroll>
      </SolutionDescription>
      <StyledVerticalLine />
    </>
  );
};

const TitleContainer = styled.div`
  margin: 0 1rem 4rem 1rem;
`;

const Title = styled.h2`
  text-align: center;
  padding-bottom: 2rem;
  font-weight: bold;
  padding-top: 2rem;
`;

const SolutionDescription = styled.div`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='3' stroke-dasharray='15%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 3rem;
  margin: auto;

  @media (max-width: 620px) {
    padding: 1rem;
  }

  @media (min-width: 820px) {
    width: 80%;
  }
`;

const ColoredTitle = styled.span`
  color: #00acea;
  display: inline;
  font-weight: bold;
`;

const StyledVerticalLine = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='4' stroke-dasharray='10%2c 23' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 100px;
  width: 1px;
  margin: auto;
`;

const StyledHeading2 = styled.h2`
  font-weight: bold;
`;

const StyledHeading4 = styled.h4`
  color: #747474;
  width: 70%;
  margin: auto;
`;

const ProblemAndSolution = styled.div`
  color: #747474;
  font-size: 1.3rem;

  @media (max-width: 620px) {
    font-size: 1.1rem;
  }
`;

const StyledTrustpilotWidget = styled.div`
  margin: 2rem;
`;
