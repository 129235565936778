import { useState } from "react";
import styled from "@emotion/styled";
import { Accordion, Image } from "react-bootstrap";
import { Typography } from "@mui/material";

import clock from "../../../../assets/images/table-icons/clock.svg";
import doubleCheckmark from "../../../../assets/images/table-icons/double-checkmark.svg";
import { MarkdownGrid } from "../../../shared/components/markdown-grid/Markdown-Grid";
import { Mixpanel } from "../../../../../Mixpanel";

interface Content {
  allowedForUserType: string;
  description: string;
  execution: string;
  group: string;
  level: string;
  title: string;
  type: string;
  _id: string;
}

export const DropdownItem: React.FC<{
  staticcontent: Content;
  userType: string;
  userId: string;
}> = ({ staticcontent, userType, userId }) => {
  const [status, setStatus] = useState(staticcontent.execution);
  const resolveTaskSubmissionStatus = (status: string) => {
    if (status === "STARTED") {
      return (
        <div>
          <Image src={doubleCheckmark} />
        </div>
      );
    }
    return (
      <div>
        <Image src={clock} />
      </div>
    );
  };

  return (
    <StyledAccordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header
          onClick={() => {
            Mixpanel.track("Static content opened - " + staticcontent.title);
          }}
        >
          {resolveTaskSubmissionStatus(status)}
          <ContentTitle>{staticcontent.title}</ContentTitle>
        </Accordion.Header>
        <Accordion.Body>
          <MarkdownGrid
            userType={userType}
            userId={userId}
            staticcontent={staticcontent}
            setTaskSubmissionResponse={(status: string) => setStatus(status)}
          />
        </Accordion.Body>
      </Accordion.Item>
    </StyledAccordion>
  );
};

const ContentTitle = styled(Typography)`
  color: #000000;
  font-size: 1.3rem;
  padding-left: 1.6rem;
`;

const StyledAccordion = styled(Accordion)`
  margin: auto;

  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 1600px) {
    width: 50%;
  }
`;
