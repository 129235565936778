import styled from "@emotion/styled";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { BlackWhiteCard } from "./BlackWhiteCard";
import coding from "../../../../assets/images/categories/coding.svg";
import database from "../../../../assets/images/categories/database.svg";
import linux from "../../../../assets/images/categories/linux.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const Environments = () => {
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery("(max-width:819px)");

  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__lightSpeedInRight">
        <Title theme={theme}>
          Execute your tests in <br />3 different Environments
        </Title>
      </AnimationOnScroll>

      <Cards isSmallerDevice={isSmallerDevice}>
        <BlackWhiteCard
          description={
            <>
              <p>
                Writing code or automated tests on our platform is
                straightforward and does not require any setup on your machine.
              </p>
              Just log in, and you can start learning.
            </>
          }
          icon={coding}
          title="CODE"
        />
        <BlackWhiteCard
          description={
            <>
              <p>
                Writing a SQL sentence requires a configured database with some
                pre-existing data.
              </p>
              The database on our platform is ready and waiting for you to start
              writing and learning SQL commands.
            </>
          }
          icon={database}
          title="SQL"
        />
        <BlackWhiteCard
          description={
            <>
              <p>
                Writing Linux commands is exciting, but we need to have the
                Linux operating system configured for that to be possible.
              </p>
              We have prepared one for you. Just log in, and you can start with
              writing commands.
            </>
          }
          icon={linux}
          title="LINUX"
        />
      </Cards>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  padding: 2rem 1rem 6rem 1rem;
  font-weight: bold;
`;

const Cards = styled.div<{ isSmallerDevice: boolean }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: ${(props: any) => (props.isSmallerDevice ? "column" : "row")};
`;
