import { styled, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import rocketImg from "../../../assets/images/rocket.svg";
import axios from "axios";
import { Mixpanel } from "../../../../Mixpanel";
import { getBeServerEndpoint, getPlatformEndpoint } from "../../../config";
import { getUser, setNewUser } from "../../utils";
import toast from "react-hot-toast";
import { checkIfGuestExists } from "../../utils/guestCheck";
import { useGoogleLogin } from "@react-oauth/google";
import { setLoginState, setLogoutState } from "../../../actions";
import { useDispatch } from "react-redux";
import { Dispatch, SetStateAction } from "react";

interface TrialButtonModel {
  text: string;
  setIsUsedFreemium?: Dispatch<SetStateAction<boolean>>;
}

export const TrialButton = (props: TrialButtonModel) => {
  let { text, setIsUsedFreemium } = props;
  const isGuestUser = checkIfGuestExists();
  const dispatch = useDispatch();

  const checkCurrentUser = () => {
    if (isGuestUser) {
      googleLogin();
    } else {
      activateFreemium();
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      const accessToken = tokenResponse?.access_token;
      const { data } = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      onSuccess(data);
    },
  });

  const onSuccess = (data) => {
    if (data) {
      axios
        .post(
          getBeServerEndpoint() + "/users/auth/login",
          {
            username: data?.email,
            name: data?.name,
            googleId: data?.email,
          },
          {
            headers: {
              Authorization: "Basic dGVzdDp0ZXN0",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response: { data: any }) => {
          if (response && response.data) {
            dispatch(setLoginState());
            let userLoginRes = response.data;
            userLoginRes.imageUrl = data.picture;
            setNewUser(userLoginRes);
            dispatch(setLoginState());
            console.log("Login successful:", userLoginRes);
            Mixpanel.alias(userLoginRes.username);
            Mixpanel.people.set({ $username: userLoginRes.username });
            Mixpanel.track("Successful login.");
          }
        })
        .catch((error: any) => {
          console.error("Login error:", error);
          dispatch(setLogoutState());
          Mixpanel.track("Unsuccessful login.");
        });
    }
  };

  const activateFreemium = () => {
    const currentUser = getUser() as string;
    let userDetails = JSON.parse(currentUser);
    const message =
      "14 days trial perid has been activated, enjoy. BR, QASkills Team";

    const promise = axios
      .get(
        getPlatformEndpoint() + `/user/${userDetails?._id}/activatefreemium`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        Mixpanel.track("Freemium has been activated.");
        userDetails.usedFreemium = true;
        setNewUser(userDetails);
        setIsUsedFreemium?.(true);
      })
      .catch((error: any) => {
        if (error) {
          throw new Error(error.message);
        }
      });

    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error.message,
    });
  };

  return (
    <StyledButton onClick={() => checkCurrentUser()} isGuestUser={isGuestUser}>
      <div className="icon-container">
        {isGuestUser ? (
          <div className="gift" style={{ fontSize: "20px" }}>
            🎁
          </div>
        ) : (
          <img
            src={rocketImg}
            alt="trial icon"
            height={30}
            className="rocket"
          />
        )}
      </div>

      <Typography variant="caption">
        <b>{text}</b>
      </Typography>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ isGuestUser: boolean }>`
  position: relative;
  height: 60px;
  width: 300px;
  background: #6c2dc7;
  border-radius: 50px;
  border: none !important;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  border: 2px solid #6c2dc7 !important;
  white-space: nowrap;

  .icon-container {
    position: absolute;
    left: ${(props: any) => (props.isGuestUser ? "7px" : "10px")};
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.8s ease-in-out;
  }

  .rocket {
    transition: transform 0.1s ease-in-out;
  }

  &:hover .icon-container .gift {
    animation: rotateForward 0.6s linear forwards;
  }

  &:not(:hover) .icon-container .gift {
    animation: rotateBackward 0.6s linear forwards;
  }

  &:hover {
    background: white;
    color: #6c2dc7;
    border-width: 3px;
    box-shadow: 5px 5px 17px 0px rgba(0, 0, 0, 0.75);

    .icon-container {
      transform: ${(props: any) =>
        props.isGuestUser
          ? "translate(257px, -50%)"
          : "translate(244px, -50%)"};
    }

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      background: white !important;
      color: #6c2dc7;
    }

    .rocket {
      filter: none;
      transform: rotate(45deg);
    }

    @keyframes rotateForward {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes rotateBackward {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
  }
`;
