import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import pathOneBug from "../../../../assets/images/bug-icons/path-1-bug.svg";
import pathTwoBug from "../../../../assets/images/bug-icons/path-2-bug.svg";
import transparentBugRed from "../../../../assets/images/bug-icons/transparent-bug-red.svg";
import unitTestIcon from "../../../../assets/images/testing-levels/unit-testing-illustration.svg";
import restApiIcon from "../../../../assets/images/testing-levels/api-illustration.svg";
import endToEndIcon from "../../../../assets/images/testing-levels/e2e-illustration.svg";
import { TransparentCardWithIcon } from "./TransparentCardWithIcon";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const TestingLevels = () => {
  const isSmallerDevice = useMediaQuery("(max-width:767px)");

  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__flipInX">
        <Title>Learn different levels of testing</Title>
      </AnimationOnScroll>

      <UnitTesting>
        <PathOneBug>
          <img src={pathOneBug} alt="Path one bug icon" />
        </PathOneBug>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <TransparentCardWithIcon
            icon={!isSmallerDevice ? unitTestIcon : ""}
            title="Unit Testing"
            description={
              <>
                <p>
                  Unit testing is an excellent instrument for software quality
                  and has been for decades.
                </p>
                <p>
                  Unit tests ensure that an application meets its software
                  design specifications and behaves as intended.
                </p>
                Writing these tests is an essential skill and can be very useful
                for a automation tester.
              </>
            }
            type="unit"
          />
        </AnimationOnScroll>
      </UnitTesting>

      <RestApiTesting>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
          <TransparentCardWithIcon
            icon={!isSmallerDevice ? restApiIcon : ""}
            title="REST API Testing"
            description={
              <>
                <p>
                  REST API testing is the only reliable way to provide clients
                  with secure and scalable connections between various
                  platforms.
                </p>
                <p>
                  This tool can transform your user interface experience, and
                  with REST API testing, we protect users from malicious
                  operations to maximize time efficiency.
                </p>
                Every modern application is build by software engineers with use
                of this technology, and it is mandatory to be verified by QA
                Engineer.
              </>
            }
            type="restApi"
            iconSide="left"
          />
        </AnimationOnScroll>
        <PathTwoBug>
          <img src={pathTwoBug} alt="Path two bug icon" />
        </PathTwoBug>
      </RestApiTesting>

      <EndToEndTesting>
        <BugIcon>
          <AnimationOnScroll animateOnce animateIn="animate__rollIn">
            <img src={transparentBugRed} alt="Transparent red bug icon" />
          </AnimationOnScroll>
        </BugIcon>

        <div>
          <TransparentCardWithIcon
            icon={!isSmallerDevice ? endToEndIcon : ""}
            title="End-To-End Testing"
            description={
              <>
                <p>
                  End To End testing represents testing the entire software
                  product from beginning to end to ensure the application flow
                  behaves as expected.
                </p>
                It defines the product’s system dependencies, and QA writes it
                after manual testing.
              </>
            }
            type="endToEnd"
          />
        </div>
      </EndToEndTesting>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  margin-bottom: 5rem;
  font-weight: bold;
  padding-top: 2rem;
`;

const UnitTesting = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20rem;
  @media (max-width: 1750px) {
    margin-left: 0;
  }

  > :nth-of-type(2) {
    @media (max-width: 1400px) {
      width: 90%;
    }
  }
`;

const RestApiTesting = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 15rem;
  @media (max-width: 1750px) {
    margin-right: 0 !important;
  }
  @media (max-width: 1023px) {
    margin-left: 0;
  }

  > :nth-of-type(1) {
    @media (max-width: 1400px) {
      width: 90%;
      margin-left: 0 !important;
    }
  }
`;

const EndToEndTesting = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 27rem;
  @media (max-width: 1700px) {
    margin-left: 0;
  }

  > :nth-of-type(2) {
    margin-left: 20rem;
    width: 100%;
    @media (max-width: 1200px) {
      margin-left: 0;
      width: 90%;
    }
    @media (max-width: 1023px) {
      width: 90%;
    }
  }

  > :nth-of-type(1) {
    display: contents;
    @media (max-width: 1400px) {
      display: none;
    }
  }

  img {
    display: flex;
    justify-content: center;
    vertical-align: center;
  }
`;

const PathOneBug = styled.div`
  margin-top: 3rem;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const PathTwoBug = styled.div`
  margin-top: 9rem;
  display: flex;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const BugIcon = styled.div`
  img {
    margin-top: 5rem;
  }
`;
