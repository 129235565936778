import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const TermsOfUse = () => {
  return (
    <Container>
      <PageIntro
        description={
          <>
            <p>
              Welcome to QASkills! These Terms of Use ('Terms') govern your
              access to and use of our QA e-learning platform.
            </p>
            <p>
              By using our platform, you agree to comply with these Terms, which
              constitute a legally binding agreement between you and QASkills.
            </p>
            If you do not agree with any part of these Terms, please do not
            access or use our platform.
          </>
        }
        subtitle="Terms Of Use"
        title="Terms Of Use"
      />
      <VerticalLine />

      <StyledAccordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Use of the Website</Accordion.Header>
          <Accordion.Body>
            By using this website and its services, we assume that you fully
            agree and accept the terms and privacy policy. If you do not agree
            with the site's terms of use and/or privacy policy, you must not use
            this site or the services it offers. The use of the website and the
            company's services is expressly conditioned on the agreement with
            all the terms of this contract, excluding all other terms.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="2">
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Who is eligible for training at QASkills?
          </Accordion.Header>
          <Accordion.Body>
            For all people to spread their knowledge is the basic idea with
            which QASkills was founded. QASkills fights for everyone's right to
            know and for the reduction of any form of discrimination in society,
            especially in the education process. At QASkills, knowledge is
            available to everyone regardless of gender, nationality, religion or
            any other affiliation. The same rights are exercised by all
            participants, regardless of educational level, previous knowledge or
            abilities. Foreign nationals enjoy the same rights and can use
            QASkills educational programs under equal conditions, if the
            language barrier is not a problem.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="3">
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Ways of learning and checking knowledge during participation in the
            educational courses of the QASkills community
          </Accordion.Header>
          <Accordion.Body>
            The education process is realized through online learning. All the
            necessary learning materials are delivered to users on the online
            learning platform, through which the process of checking knowledge
            and taking final tests is carried out.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="4">
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Participant notification can be done
          </Accordion.Header>
          <Accordion.Body>
            - Via email - Via the QASkills website - Through a learning platform
            The participant attends the course at an individual pace, completing
            assignments and progressing in the learning process according to
            their own capabilities, wishes and plans. Only the start and end
            dates are binding. Username and password are opened in the name of
            the candidate who enrolled in the course. Forwarding this
            information to third parties is strictly prohibited. Any abuse will
            be immediately sanctioned. The user of educational courses is
            obliged to inform QASkills about changes in all personal data during
            participation in educational programs. The use of materials and
            services is at the user's own risk. The method of learning and
            checking knowledge during participation in the educational courses
            of the QASkills community The user receives lessons and supporting
            material for learning on the learning platform. The user does not
            need any additional materials to successfully complete the QASkills
            course, all the materials are provided and available on the online
            learning platform.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="5">
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            The right to obtain a QASkills certificate
          </Accordion.Header>
          <Accordion.Body>
            Every QASkills participant can obtain a certificate of completion of
            the QASkills course if he/she has successfully passed all the
            lessons in the course, and does not have to pass the final test and
            practical tasks. Every QASkills participant can obtain a certificate
            of successfully completing the QASkills course if he/she has
            successfully completed all the lessons in the course, successfully
            completed the practical tasks and passed the final knowledge
            assessment test. The certificate and confirmation in the electronic
            version are free of charge.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="6">
        <Accordion.Item eventKey="6">
          <Accordion.Header>Technical support</Accordion.Header>
          <Accordion.Body>
            Each participant will receive a written instruction for using the
            online classroom. For any help using the learning platform, the user
            can contact us by sending an email to office@qaskills.io or via the
            Technical Support link in the main drop-down menu. Technical support
            works from 9 a.m. to 5 p.m. We try to respond to the user on the
            same day, and at the latest, during the period of intensive
            communication, within a couple of working days.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="7">
        <Accordion.Item eventKey="7">
          <Accordion.Header>VAT statement</Accordion.Header>
          <Accordion.Body>
            Under full material and criminal liability in front of the company
            QASkills Labs DOO we declare that the above mentioned company is not
            registered in the VAT system of Serbia.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>

      <StyledAccordion defaultActiveKey="8">
        <Accordion.Item eventKey="8">
          <Accordion.Header>Copyright</Accordion.Header>
          <Accordion.Body>
            All material on the website belongs to QASkills.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const StyledAccordion = styled(Accordion)`
  @media (min-width: 768px) {
    width: 90%;
    margin: auto;
  }

  @media (min-width: 1200px) {
    width: 60%;
    margin: auto;
  }
`;
