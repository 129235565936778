import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Iframe from "react-iframe";
import { ReactPainter } from "react-painter";
import styled from "@emotion/styled";
import { AddCommentForm } from "./AddCommentForm";
import { getUser } from "../../../utils";
import { ContainedButton } from "../buttons/ContainedButton";
import cards from "../../../../assets/images/credit-cards.svg";
import { Mixpanel } from "../../../../../Mixpanel";
import MarkdownWithHighlight from "../markdown-grid/MarkdownWithHighlight";
import MarkdownView from "react-showdown";

interface QuestionModel {
  howto: string;
  description: string;
  group: string;
  initialcode: string;
  level: string;
  oursolution: string;
  question: string;
  restApiDocs: string;
  testingAppUrl: string;
  title: string;
  type: string;
  _id: string;
}

interface EditorTaskProps {
  taskType: string;
  question: QuestionModel;
}

export const EditorTask = (props: EditorTaskProps) => {
  const { taskType, question } = props;
  const userType = JSON.parse(getUser() as string)?.type;

  Mixpanel.track(
    "Editor opened - task type:" +
    taskType +
    " - for question: " +
    question.question
  );

  return (
    <Tabs className="unselectable">
      <TabList>
        {taskType === "testcase" && <Tab>How To</Tab>}

        <Tab>
          <span className="task-tab-step">Task</span>
        </Tab>
        <Tab>
          <span className="description-tab-step">Description</span>
        </Tab>
        <Tab>
          <span className="our-solution-tab-step">Our Solution</span>
        </Tab>
        <Tab>
          <span className="discussions-tab-step">Discussions</span>
        </Tab>

      </TabList>

      {taskType === "testcase" && (
        <TabPanel className="tab-height">
          <div>
            <MarkdownView
              markdown={question.howto}
              options={{ tables: true, emoji: true }}
            />
          </div>
        </TabPanel>
      )}

      <TabPanel className="tab-height">
        <div>
          <MarkdownView
            markdown={question.question}
            options={{ tables: true, emoji: true }}
          />
        </div>
      </TabPanel>
      <TabPanel className="tab-height">
        <div className="border-collapse">
          Estimated reading:{" "}
          {Math.round(question.description.split(" ").length / 130)} min
          <MarkdownWithHighlight text={question.description} />
        </div>
      </TabPanel>
      <TabPanel className="tab-height">
        <div>
          <MarkdownWithHighlight text={question.oursolution} />
        </div>
      </TabPanel>
      <TabPanel className="tab-height">
        {userType === "PAID" ? (
          <AddCommentForm buttonText="Add Comment" url="/comment" />
        ) : (
          <FreeUsersMessage>
            <h5>This functionality is available only to paid users.</h5>
            <ContainedButton
              text="Buy QASkills"
              variant="contained"
              disabled={false}
              icon={cards}
              fromPage="discussions tab."
            />
          </FreeUsersMessage>
        )}
      </TabPanel>
    </Tabs>
  );
};

const StyledReactPainter = styled.div`
  background: #ffffff;
`;

const FreeUsersMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
`;
